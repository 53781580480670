import { Button } from "@material-ui/core"
import React, { FC } from "react"
import { RulesOlympics } from "./RulesOlympics"
import { RulesFootball } from "./RulesFootball"
import { Rules } from "./Rules"

interface Props {
  onSneakPreview: () => void
  startTime: number
  title: string
  isOlympics: boolean
}
export const Signup: FC<Props> = ({ onSneakPreview, startTime, title, isOlympics }) => {
  return (
    <div className="App">
      <h1>{title}</h1>
      <ul>
        <Rules isOlympics={isOlympics} startTime={startTime}/>
        <li>
          The results page will be available an hour before start.
          <button onClick={onSneakPreview}>preview</button>
        </li>
      </ul>
    </div>
    
  )
}
