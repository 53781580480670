import { Button } from "@material-ui/core"
import React, { FC } from "react"

interface Props {
}
export const RulesOlympics: FC<Props> = ({  }) => {
  return (
    <>
        <li>
          <strong>Match Lottery:</strong> Each participant will be assigned 1x2 lines for every match, plus some special bets. Points will be awarded based on the betting odds for each match, with additional multipliers for knockout stage games. No skill involved, excitement guaranteed for every game.
        </li>
        <li>Prize distribution: 50% for the winner, 30% for second place, 20% for third place. 70%:30% when 8 or less participants. Amounts will be rounded arbitrately.</li>
        <li>Entry fee: €50 per ticket, one ticket per person.</li>
        <li>No ISG trophy for the loser, as it's only for football bets</li>
        <li>
          Registration: Register by €50 (matches only) with MobilePay (preferred) to 050-5872087 or with a bank transfer to FI65 3939 0033 5711 42</li>
        
    </>
    
  )
}