import { Button } from "@material-ui/core"
import React, { FC } from "react"

interface Props {
}
export const RulesFootball: FC<Props> = ({  }) => {
  return (
    <>
        <li>
          <strong>Match Lottery:</strong> Each participant will be assigned 1x2 lines for every match, plus some special bets. Points will be awarded based on the betting odds for each match, with additional multipliers for knockout stage games. No skill involved, excitement guaranteed for every game.
        </li>
        <li>Prize distribution: 50% for the winner, 30% for second place, 20% for third place.</li>
        <li>Entry fee: €50 per ticket, one ticket per person.</li>
        <li>Consolation prize for the loser: a loan of the "Incest and Shit with Grannies" DVD until the next tournament.</li>
        <li>
          <strong>Winner Lottery:</strong> Each participant will be randomly assigned one of the top teams. The remaining teams will be randomly assigned to participants.
        </li>
        <li>Entry fee: €20 per ticket, one ticket per person.</li>
        <li>
          Registration: Register by transferring €20 (winner only), €50 (matches only), or €70 (winner+matches) with MobilePay to 050-5872087 or with a bank transfer to FI65 3939 0033 5711 42</li>
        
    </>
    
  )
}