import React, { useState } from "react"

import { Main } from "./views/Main"
import { Signup } from "./views/Signup"


const startTime = 1722016800000
const isOlympics = true
export function App() {
  const [showMain, setPreview] = useState<boolean>(shouldShowMain())

  if (showMain) return <Main startTime={startTime} isOlympics={isOlympics}/>
  else return <Signup onSneakPreview={() => setPreview(true)} startTime={startTime} title="Paris 2024" isOlympics={isOlympics}/>
}
function shouldShowMain() {
  return Date.now() > startTime
}
