import { Button } from "@material-ui/core"
import React, { FC } from "react"
import { RulesOlympics } from "./RulesOlympics"
import { RulesFootball } from "./RulesFootball"

interface Props {
  startTime: number
  isOlympics: boolean
}
export const Rules: FC<Props> = ({ startTime, isOlympics }) => {
  return (
      <>
        {isOlympics?<RulesOlympics/>:<RulesFootball/>}
        <li>Once entered, the odds and multipliers will not be changed unless in the case of a clear clerical error</li>
        <li>Whining period for special bets, their odds etc ends when draw has been made.</li>
        <li>In unclear cases JPH will be the sole judge and dictator</li>
        <li>The draw will be based on a deterministic algorithm and a seed that will be released here at event start: 
          https://beacon.nist.gov/beacon/2.0/pulse/time/{startTime}
</li>
      </>
    
  )
}
